<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="협력사 협의체 기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-show="tabParam.councilId" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
              <c-btn
                v-show="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="tabParam"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                required
                :editable="editable"
                label="제목"
                name="councilTitle"
                v-model="tabParam.councilTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                required
                :editable="editable"
                label="일자"
                name="councilDate"
                v-model="tabParam.councilDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant
                required
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="tabParam.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :rows="4"
                label="내용"
                name="councilContents"
                v-model="tabParam.councilContents">
              </c-textarea>
            </div>
          </template>
        </c-card>
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-sm">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable">
            </c-upload>
          </div>
        </template>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'council-detail',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        councilId: '',
        plantCd: '',
        councilDate: '',
        councilContents: '',
        councilTitle: '',
        coucilVendorModels: [],
        deletecoucilVendorModels: [],

        regUserId: '',
        chgUserId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SOP_COUNCIL',
        taskKey: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachable: false,
      saveUrl: transactionConfig.mdm.cim.vendor.council.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      updateMode:false,
      realKey: '',
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.insertUrl = transactionConfig.mdm.cim.vendor.council.insert.url;
      this.updateUrl = transactionConfig.mdm.cim.vendor.council.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.council.delete.url;
      // code setting
      // list setting
    },
    saveData() {
      if (this.tabParam.councilId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.tabParam.regUserId = this.$store.getters.user.userId;
                this.tabParam.chgUserId = this.$store.getters.user.userId;

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.councilId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.tabParam.councilId) {
        this.$set(this.attachInfo, 'taskKey', this.tabParam.councilId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.tabParam.councilId = result.data
      this.$emit('changeStatus', result.data)
    },
  }
};
</script>
